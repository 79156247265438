import { graphql } from 'gatsby';
import queryString from 'query-string';
import React, { FC } from 'react';

import { useGetRewardDetailPageLink } from '@/bits/links/useLink';
import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  ControlledModal,
} from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import {
  NextIcon,
  PlusIcon,
  PreviousIcon,
  RefreshIcon,
} from '@/components/icons';
import { useTranslate } from '@/contexts';
import RewardCreateForm from '@/forms/reward-create-form/RewardCreateForm';
import {
  RewardStatus,
  RewardTypeEnum,
  SearchRewardsOrderBy,
} from '@/globalTypes';
import { Rewards_viewer_rewards_edges_node_players } from './__generated__/Rewards';
import { SanityRewardsHistoryBlockFragment } from './__generated__/SanityRewardsHistoryBlockFragment';
import useRewards from './useRewards';

export const Fragment = graphql`
  fragment SanityRewardsHistoryBlockFragment on SanityRewardsHistoryBlock {
    title {
      ...LocaleString
    }
  }
`;

const RewardHistoryBlock: FC<{
  block: SanityRewardsHistoryBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();

  const getRewardDetailLink = useGetRewardDetailPageLink();

  const {
    rewards,
    fetching,
    nextPage,
    previousPage,
    refresh,
    orderBy,
    desc,
    setOrderBy,
    defaultFilter,
    updateFilter,
  } = useRewards();

  const { DataGrid, GlobalCardOptions } = useDataGrid({
    name: 'reward',
    data: rewards,
    fetching,
    orderBy,
    setOrderBy,
    desc,
    defaultFilter,
    updateFilter,
    schema: (s) => [
      s.dateTimeValue({
        field: 'createdAt',
        title: 'Created At',
        orderBy: SearchRewardsOrderBy.createdAt,
        fromFilterField: 'createdAtFrom',
        toFilterField: 'createdAtTo',
      }),
      s.stringValue({
        field: 'rewardId',
        title: 'Reward Id',
        filterField: 'rewardId',
      }),
      s.stringValue({
        field: 'createdBy',
        title: 'Created By',
        getValue: ({ row }) =>
          row.createdBy?.firstName + ' ' + row.createdBy?.lastName,
      }),
      s.stringValue({
        field: 'name',
        title: 'Reward Name',
        filterField: 'rewardName',
        orderBy: SearchRewardsOrderBy.name,
        linkTo: ({ row }) =>
          getRewardDetailLink(
            `${row.id}?${queryString.stringify({
              tabIndex: 0,
            })}`,
          ) || '',
      }),
      s.enumValue({
        field: 'rewardType',
        title: 'Type',
        e: RewardTypeEnum,
        filterField: 'rewardTypes',
        isMulti: true,
        getValue: ({ row }) => row.rewardType?.name,
      }),
      s.stringValue({
        field: 'players',
        title: 'Player(s)',
        getValue: ({ row }) =>
          returnAmountOfPlayers(row.totalPlayers, row.players),
        linkTo: ({ row }) =>
          getRewardDetailLink(
            `${row.id}?${queryString.stringify({
              tabIndex: 1,
            })}`,
          ) || '',
      }),
      s.dateTimeValue({
        field: 'startsAt',
        title: 'Starts At',
        fromFilterField: 'startsAtFrom',
        toFilterField: 'startsAtTo',
      }),
      s.dateTimeValue({
        field: 'endsAt',
        title: 'Ends At',
        fromFilterField: 'endsAtFrom',
        toFilterField: 'endsAtTo',
      }),
      s.enumValue({
        field: 'status',
        title: 'Status',
        filterField: 'rewardStatus',
        e: RewardStatus,
      }),
    ],
  });

  return (
    <Card
      size="lg"
      title={t(block.title)}
      showOptionsAtBottom
      options={
        <CardOptions>
          <CardOptionsButton
            disabled={!previousPage}
            onClick={() => previousPage && previousPage()}
          >
            <PreviousIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!nextPage}
            onClick={() => nextPage && nextPage()}
          >
            <NextIcon />
          </CardOptionsButton>
          <GlobalCardOptions />
          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
          <ControlledModal content={<RewardCreateForm />}>
            <CardOptionsButton>
              <PlusIcon />
            </CardOptionsButton>
          </ControlledModal>
        </CardOptions>
      }
    >
      <CardBody>
        <DataGrid />
      </CardBody>
    </Card>
  );
};

const returnAmountOfPlayers = (
  totalPlayers: number,
  { edges }: Rewards_viewer_rewards_edges_node_players,
) => {
  if (!edges) return null;

  if (totalPlayers > 1) {
    return `Custom (${totalPlayers})`;
  } else if (totalPlayers === 0) {
    return 'Unassigned players';
  }

  return `${edges[0]?.node.playerId}`;
};

export default RewardHistoryBlock;
